import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IGlobalStoreState } from '../../../store';
import { EventType, IEventCategory } from '../../../store/event/types';
import { hideAdButton, hideCitySelector, showCitySelector } from '../../../store/header/actions';
import { AnalyticService } from '../../../services';
import { SellEventButton } from '../../../components/event/sellEventButton';
import { CategoryCarousel } from '../../../components/event/categoryCarousel';
import { CategorizedEventCarousel } from '../../../components/event/categorizedEventCarousel';
import useApi from '../../../services/apiService';
import { SearchInput } from '../../../components/event/searchInput';
import { DatePicker } from '../../../components/event/datePicker/datePicker';
import { DatepickerCarousel } from '../../../components/event/datePicker/datepickerCarousel';
import { Footer } from '../../footer';
import { Events } from '../../../components/event/events';
import { useQuery } from '../../../helpers/customHooks';
import { Routes } from '../../../store/routes';

const useStyles = makeStyles()(() => ({
  gridCategories: {
    marginBottom: 43,
  },
  containerPlayer: {
    position: 'absolute',
    width: '100%',
    height: '470px',
    top: 0,
    zIndex: -1,
  },
  gradientPlayer: {
    position: 'absolute',
    zIndex: 10,
    background: 'linear-gradient(#000000 0%, rgba(0, 0, 0, 0.4) 23.03%, rgba(28, 28, 28, 0.3) 81.88%, #1C1C1C 100%)',
    minWidth: '100%',
    minHeight: '100%',
  },
  contentPage: {
    marginTop: '134px',
    zIndex: 1,
    padding: 16,
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: '14px',
    cursor: 'default',
  },
  searchInput: {
    paddingTop: '50px',
    paddingBottom: '170px',
  },
  videoPlayer: {
    height: 'inherit',
    objectFit: 'cover',
    width: '100%',
    zIndex: 0,
  },
  contentPlayer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
}));

export const DashboardPage = () => {
  const dispatch = useDispatch();
  const analyticSrv = new AnalyticService();
  const query = useQuery();
  const { t } = useTranslation();

  const cityQuery = query
    .get('city')
    ?.trim()
    .replace(/[0-9,%]/, ' ');

  const showDatepicker = useSelector<IGlobalStoreState, boolean>((state) => state.appSettings.showDashboardDatepicker);
  const showPopularEvents = useSelector<IGlobalStoreState, boolean>(
    (state) => state.appSettings.showDashboardPopularEvents
  );
  const showCategories = useSelector<IGlobalStoreState, boolean>((state) => state.appSettings.showDashboardCategories);
  const showPopularTours = useSelector<IGlobalStoreState, boolean>(
    (state) => state.appSettings.showDashboardPopularTours
  );
  const showDashboardEventsByCategories = useSelector<IGlobalStoreState, boolean>(
    (state) => state.appSettings.showDashboardEventsByCategories
  );
  const dashboardCarouselColumnEventCount = useSelector<IGlobalStoreState, number>(
    (state) => state.appSettings.dashboardCarouselColumnEventCount
  );
  const dashboardCarouselEventCount = useSelector<IGlobalStoreState, number>(
    (state) => state.appSettings.dashboardCarouselEventCount
  );

  const [categories, setCategories] = useState<IEventCategory[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [hidePopularTours, setHideDashboardPopularTours] = useState<boolean>(false);
  const [city, setCity] = useState<string>(cityQuery || '');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(null);

  const { eventsApi } = useApi();
  const { classes } = useStyles();
  const history = useHistory();

  useEffect(() => {
    analyticSrv.trackEvent('EventsList');
    dispatch(hideAdButton());
    dispatch(showCitySelector());

    return () => {
      dispatch(hideAdButton());
      dispatch(hideCitySelector());
    };
  }, []);

  useEffect(() => {
    (async () => {
      const result = await eventsApi.getEventsCategories();
      setCategories(result);
    })();
  }, []);

  const loadEvents = useCallback(
    async (
      eventType: EventType,
      search?: string | null,
      date?: Date | null,
      count?: number | null,
      city?: string | null,
      categoryId?: number | null
    ) => {
      const result = await eventsApi.getEventsByType({
        eventType,
        search,
        date,
        count,
        city,
        categoryId,
      });
      return result.map((event) => ({
        key: event.slug,
        title: event.title,
        imageUrl: event.posterUrl,
        date: event.date,
        city: event.city,
        categoryId,
        domainTypes: event.domainTypes,
      }));
    },
    []
  );

  const loadEventsByType = async (eventType) => {
    const events = await loadEvents(eventType);

    if (events.length <= 1) {
      setHideDashboardPopularTours(true);
    }

    return events;
  };

  const loadEventCategories = async () => {
    const result = await eventsApi.getEventsCategories();
    return result.map((category) => ({
      id: category.id,
      key: category.slug,
      title: category.title,
      imageUrl: category.imageUrl,
    }));
  };

  const handleDelete = useCallback(() => {
    setCity(null);
    history.replace(Routes.Main);
  }, []);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.contentPage}>
      <div className={classes.containerPlayer}>
        <div className={classes.contentPlayer}>
          <div className={classes.gradientPlayer} />
          <video className={classes.videoPlayer} preload="metadata" autoPlay loop muted playsInline>
            <source type="video/mp4" src="content/videos/demo_video.mp4" />
          </video>
        </div>
      </div>
      {false && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
          <TextContainer>
            <p>{t<string>('Dashboard.SloganFirstPart')}</p>
            <p>{t<string>('Dashboard.SloganSecondPart')}</p>
          </TextContainer>
        </Grid>
      )}

      <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.searchInput}>
        <SearchInput setCity={setCity} city={city} />
      </Grid>

      {false && (
        <Grid item>
          <SellEventButton />
        </Grid>
      )}

      {showPopularEvents && (
        <Events
          city={city}
          load={() => loadEventsByType(EventType.Popular)}
          title={t<string>('Dashboard.PopularEvents')}
        />
      )}

      {showDashboardEventsByCategories && !city && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridCategories}>
          <div>
            <Typography variant="h6" className={classes.headingText}>
              категории
            </Typography>
          </div>
          <CategoryCarousel load={() => loadEventCategories()} />
        </Grid>
      )}
      {showDatepicker && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
          <DatePicker
            selectedDate={selectedDate}
            handleDelete={handleDelete}
            city={city}
            onDateChanged={setSelectedDate}
            categories={categories}
            onCategoryChange={setSelectedCategoryId}
          />
        </Grid>
      )}

      {showDatepicker && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
          <DatepickerCarousel
            reloadDependencyValue={{ selectedDate, city }}
            load={() =>
              loadEvents(EventType.Date, city, selectedDate, dashboardCarouselEventCount, null, selectedCategoryId)
            }
            defaultRowsCount={dashboardCarouselColumnEventCount}
          />
        </Grid>
      )}

      {showPopularTours && !hidePopularTours && (
        <Events title={t<string>('Dashboard.PopularTours')} load={() => loadEventsByType(EventType.Tour)} />
      )}

      {showCategories && <CategorizedEventCarousel categories={categories} />}
      <Footer />
    </Grid>
  );
};

const TextContainer = styled.div`
  padding: 15px 25px;

  p {
    text-align: center;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    /* or 18px */
  }

  @media screen and (min-width: 576px) {
    p {
      font-size: 18px;
    }
  }
`;
