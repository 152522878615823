import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as qs from 'query-string';

import { IGlobalStoreState } from '../../../store';
import { Utils, AnalyticService } from '../../../services';
import { BookStatus, AlertType, KeyErrors, KeySuccess } from "../../../store/enums";
import { checkoutComplete, checkoutClear, redoCheckout } from '../../../store/checkout/actions';
import { ICheckoutStoreState } from '../../../store/checkout/types';
import { IErrorStoreState } from '../../../store/app/types';
import { PaymentCompleteResult } from '../../../store/payment/types';
import { CheckoutHelper } from '../../../helpers';
import { withTranslation } from 'react-i18next';
import RouteService from '../../../services/routeService';
import { setResultInfo } from "../../../store/resultInformarion/actions";
import { ResultInfoButtonConfigType, ResultInfoType } from "../../../store/resultInformarion/types";

interface IBookResultPageProps {
    type: BookStatus;
    refnumber: string;
    checkout: ICheckoutStoreState;
    isLoading: boolean;
    error: IErrorStoreState;
    isAuthorized: boolean;

    go: (url: string) => void;
    replace: (url: string) => void;
    checkoutComplete: (refNumber: string, cancel: boolean) => Promise<PaymentCompleteResult>;
    checkoutClear: () => Promise<void>;
    showAlert: (config: ResultInfoType) => void;
    hideAlert: () => void;
    checkoutRedo: () => Promise<void>;
    t: (text: string) => string
}

interface IBookResultPageState {
    isHandlingResult: boolean;
}

class BookResultPage extends React.PureComponent<IBookResultPageProps, IBookResultPageState> {
    private routeSrv = new RouteService();
    private analyticSrv = new AnalyticService()
    constructor(props: IBookResultPageProps) {
        super(props);

        this.state = {
            isHandlingResult: false,
        };
    }

    componentWillMount() {
        this.handlePaymentResult(this.props, this.state);
    }

    componentDidMount() {
        if (this.props.type === BookStatus.Timeout) {
            this.props.checkoutClear();
            this.handlePaymentResult(this.props, this.state);
            this.props.showAlert({
                type: AlertType.Error,
                key: KeyErrors.PaymentTimeout,
                title: 'Alert.PaymentTimedOut',
                buttons: [
                    {
                        title: "Result.GoToMainPage",
                        callback: () => this.props.go('/'),
                    },
                ],
            });
            this.props.replace(this.routeSrv.getResultStatusRoute(AlertType.Error, KeyErrors.PaymentTimeout));
        }
    }

    componentWillReceiveProps(nextProps: IBookResultPageProps) {
        const isCheckoutChanged = !Utils.isEqual(this.props.checkout, nextProps.checkout);
        const isTypeChanged = this.props.type !== nextProps.type;
        const isRefNumberChanged = this.props.refnumber !== nextProps.refnumber;
        if (isCheckoutChanged || isTypeChanged || isRefNumberChanged) {
            this.handlePaymentResult(nextProps, this.state);
        }
    }

    render() {
        return null;
    }

    private handlePaymentResult(props: IBookResultPageProps, state: IBookResultPageState) {
        if (props.refnumber) {
            const cancel = props.type === BookStatus.Timeout;
            const final = (checkout: ICheckoutStoreState) => {
                this.setState({ ...state, isHandlingResult: false }, () => {
                    this.handlePaymentCompletion(checkout);
                });
            };

            if (CheckoutHelper.getIsCompleted(props.checkout)) {
                final(props.checkout);
            } else if (!state.isHandlingResult) {
                this.setState({ ...state, isHandlingResult: true }, () => {
                    props
                        .checkoutComplete(props.refnumber, cancel)
                        .then(() => {
                            final(props.checkout);
                        })
                        .catch(() => {
                            final(props.checkout);
                        });
                });
            }
        }
    }

    private handlePaymentCompletion(checkout: ICheckoutStoreState) {
        if (!checkout || !checkout.complete || !checkout.complete.result) return;
        const completeResult = checkout.complete.result;
        let alertType: AlertType;
        let alertKey: KeySuccess | KeyErrors;
        let title = completeResult.userMessage;
        let message = completeResult.userMessageDetails;
        let callbacks: Array<ResultInfoButtonConfigType> = [];

        if (completeResult.isProcessing) {
            alertType = AlertType.Warning;
            alertKey = KeySuccess.PaymentInProcess;
            title = 'Alert.WaitForTheServerToCompleteThePayment',
            callbacks = [
                {
                    title: "Result.GoToMainPage",
                    callback: () => this.props.go('/'),
                },
            ];
            this.props.replace(this.routeSrv.getResultStatusRoute(AlertType.Warning, KeySuccess.PaymentInProcess));
        } else if (!completeResult.isCompleted) {
            alertType = AlertType.Warning;
            alertKey = KeyErrors.PaymentFailed;
            callbacks = [
                {
                    title: "Result.TryAgain",
                    callback: async () => {
                        await this.props.checkoutRedo();
                        this.props.go(this.routeSrv.getCheckoutRoute(checkout.eventSlug, null));
                    },
                },
                {
                    title: "Result.Cancel",
                    callback: () => this.props.checkoutComplete(this.props.refnumber, true),
                },
            ];
            this.props.replace(this.routeSrv.getResultStatusRoute(AlertType.Warning, KeyErrors.PaymentFailed));
        } else {
            alertType = completeResult.isSuccess ? AlertType.Success : AlertType.Error;
            alertKey = completeResult.isSuccess ? KeySuccess.PaymentSucceeded : KeyErrors.PaymentFailed;
            message = this.props.isAuthorized && completeResult.isSuccess ? "Result.TicketsAvailabilityMessage" : message;
            callbacks = [
                {
                    title: "Result.GoToMainPage",
                    callback: () => this.props.go('/'),
                },
            ];

            if (completeResult.isSuccess && this.props.isAuthorized) {
                callbacks.push({
                    title: "Result.MyTickets",
                    callback: () => this.props.go(this.routeSrv.getUserTicketsRoute()),
                });
            }

            if (completeResult.isSuccess) {
                this.analyticSrv.trackEvent("Purchase", {
                    value: checkout.init.priceTotal,
                    currency: checkout.init.currency
                    })
            }
            this.props.checkoutClear();
            this.props.replace(this.routeSrv.getResultStatusRoute(alertType, alertKey));
        }

        this.props.showAlert({
            type: alertType,
            key: alertKey,
            title: title,
            message: message,
            buttons: callbacks,
        });
    }
}

const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => {
    const queryParams: any = qs.parse(ownProps.location && ownProps.location.search);
    return {
        type: ownProps.match && ownProps.match.params && ownProps.match.params.type,
        refnumber: queryParams.refnumber,
        checkout: state.checkout,
        isLoading: state.app.isLoading,
        error: state.app.error,
        isAuthorized: state.app.isAuthorized,
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    go: ownProps.history.push,
    replace: ownProps.history.replace,
    checkoutComplete: (refNumber: string, cancel: boolean) => dispatch(checkoutComplete(refNumber, cancel, true)),
    checkoutClear: () => dispatch(checkoutClear()),
    showAlert: (config: ResultInfoType) => dispatch(setResultInfo(config)),
    checkoutRedo: () => dispatch(redoCheckout()),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withTranslation()(BookResultPage)),
);
