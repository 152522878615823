import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

import { AnimatePresence } from 'framer-motion';
import { Button, TextInput, UserAgreementLink } from '../UI';
import { FieldError, Fieldset, FieldWrapper, FormError, FormInfo } from './commonForm';
import Constants from '../../store/constants';
import { PopUp } from '../../store/animations';

export type RegistrationFormType = {
  email: string;
  password: string;
  passwordConfirm: string;
};

type RegistrationFormProps = {
  submitTitle: string;
  onSubmit: (RegistrationFormType) => void;
  errors: any;
};

export const RegistrationForm: FC<RegistrationFormProps> = ({ submitTitle, onSubmit, errors }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('Registration.EmailRequired')).email(t('Registration.EmailIncorrect')),
    password: Yup.string()
      .required(t('Registration.PasswordRequired'))
      .min(Constants.MinPasswordLength, t('Registration.MaxLengthOfPassword')),
    passwordConfirm: Yup.mixed().test(
      'match',
      t('Registration.PasswordsNotMatch'),
      (passwordConfirm, context) => passwordConfirm === context.parent.password
    ),
  });

  const initialValues = {
    email: '',
    password: '',
    passwordConfirm: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: RegistrationFormType) => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ isValid, handleChange, handleBlur, values }) => (
        <StyledForm autoComplete="on">
          <Fieldset>
            <Field name="email">
              {({ field, form }) => (
                <FieldWrapper noted={!!form.touched.email && !!form.errors && !!form.errors.email}>
                  <TextInput
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    {...field}
                    placeholder="E-mail"
                    error={!!form.touched.email && !!form.errors && !!form.errors.email}
                  />
                  <AnimatePresence>
                    {!!form.touched.email && !!form.errors && !!form.errors.email && (
                      <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                        {t(form.errors.email)}
                      </FieldError>
                    )}
                  </AnimatePresence>
                </FieldWrapper>
              )}
            </Field>
            <Field name="password">
              {({ field, form }) => (
                <FieldWrapper noted={!!form.touched.password && !!form.errors && !!form.errors.password}>
                  <TextInput
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    {...field}
                    placeholder={t('Registration.Password')}
                    error={!!form.touched.password && !!form.errors && !!form.errors.password}
                    autoComplete="on"
                  />
                  <AnimatePresence>
                    {!!form.touched.password && !!form.errors && !!form.errors.password && (
                      <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                        {t(form.errors.password, { min: Constants.MinPasswordLength })}
                      </FieldError>
                    )}
                  </AnimatePresence>
                </FieldWrapper>
              )}
            </Field>
            <Field name="passwordConfirm">
              {({ field, form }) => (
                <FieldWrapper noted={!!form.touched.passwordConfirm && !!form.errors && !!form.errors.passwordConfirm}>
                  <TextInput
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirm}
                    {...field}
                    placeholder={t('Registration.ConfirmPassword')}
                    error={!!form.touched.passwordConfirm && !!form.errors && !!form.errors.passwordConfirm}
                    autoComplete="off"
                  />
                  <AnimatePresence>
                    {!!form.touched.passwordConfirm && !!form.errors && !!form.errors.passwordConfirm && (
                      <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                        {t(form.errors.passwordConfirm)}
                      </FieldError>
                    )}
                  </AnimatePresence>
                </FieldWrapper>
              )}
            </Field>
          </Fieldset>
          <Note>
            <UserAgreementLink>{t('Registration.RegistrationAgreements')}</UserAgreementLink>
          </Note>
          <AnimatePresence>
            {!!errors && !!errors.form && (
              <FormError variants={PopUp} initial={'enter'} animate={'exit'} key="form-error">
                {t(errors.form.details, { email: values.email }) || t(errors.form.message)}
              </FormError>
            )}
          </AnimatePresence>
          <StyledButton type="submit" disabled={!isValid}>
            {submitTitle}
          </StyledButton>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

export const Note = styled(FormInfo)<{ theme: { colors: { text: string } } }>`
  color: ${(props) => props.theme.colors.text};
`;
