import React from 'react';
import { ButtonBase, Theme, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { IGlobalStoreState } from '../../store';
import { hidePlayer, showPlayer } from '../../store/header/actions';
import { IHeaderStoreState } from '../../store/header/types';

const useStyles = makeStyles<void, 'imageBackdrop'>()((theme: Theme, _params, classes) => ({
  button: {
    position: 'relative',
    height: '42px',

    '&:hover, &$focusVisible': {
      zIndex: 1,
      [`& .${classes.imageBackdrop}`]: {
        opacity: 0.25,
      },
    },
  },
  imageBackdrop: {
    borderRadius: '24px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    opacity: 0.15,
    transition: theme.transitions.create('opacity'),
  },
  imageButton: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
  },
  imageTitle: {
    borderRadius: '24px',
    position: 'relative',
    textTransform: 'uppercase',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
}));

export const SellEventButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShowPlayer = useSelector<IGlobalStoreState, boolean>((state) => state.header.showPlayer);
  const { classes } = useStyles();

  const handleModalBtnClick = () => (isShowPlayer ? dispatch(hidePlayer()) : dispatch(showPlayer()));

  return (
    <ButtonBase
      focusRipple
      onClick={() => handleModalBtnClick()}
      className={classes.button}
      style={{ marginBottom: '110px' }}
    >
      <span className={classes.imageBackdrop} />
      <span className={classes.imageButton}>
        <Typography component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
          {t<string>('Dashboard.EarnOnATicket')}
        </Typography>
      </span>
    </ButtonBase>
  );
};
