import * as React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import { FormInfo } from './commonForm';
import { AppTheme } from '../../settings/appTheme';

declare let BeGateway: any;

interface IWidgetCheckoutFormProps {
  token: string;
}

interface IWidgetCheckoutFormState {
  formLoaded: boolean;
}

export class WidgetCheckoutForm extends React.Component<IWidgetCheckoutFormProps, IWidgetCheckoutFormState> {
  private _script: any;
  private _frame: any;
  private _DOM: any;

  constructor(props: IWidgetCheckoutFormProps) {
    super(props);

    this.state = {
      formLoaded: false,
    };
  }

  componentDidMount() {
    if (!this._script) {
      this._script = document.createElement('script');
      this._script.src = 'https://js.bepaid.by/widget/be_gateway.js';
      this._script.async = true;
      this._script.onload = () => this.buildForm();
      document.body.appendChild(this._script);
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this._script);
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <PaymentForm id="payment-form" ref={(e) => this.setRef(e)} />
            <Notes>
              <Logos />
            </Notes>
          </React.Fragment>
        )}
      </Translation>
    );
  }

  private setRef(element: any) {
    if (!this._DOM) {
      this._DOM = element;
    }
    this._DOM.addEventListener('DOMSubtreeModified', (e) => {
      const iframe = document.getElementsByTagName('iframe')[0];
      if (!this._frame && iframe) {
        this._frame = iframe;
        this._frame.onload = (e) => {
          this.setState({ formLoaded: true });
        };
      }
    });
  }

  private buildForm() {
    const options = {
      checkout_url: 'https://checkout.bepaid.by',
      token: this.props.token,
      checkout: {
        iframe: true,
        transaction_type: 'payment',
      },
      closeWidget: function (status) {
        // возможные значения status
        // successful - операция успешна
        // failed - операция не успешна
        // pending - ожидаем результат/подтверждение операции
        // redirected - пользователь отправлен на внешнюю платежную систему
        // error - ошибка (в параметрах/сети и тд)
        // null - виджет закрыли без запуска оплаты
        //console.debug('close widget callback=', status)
      },
    };

    const pf = new BeGateway(options);
    pf.createWidget();
  }
}

const PaymentForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > #beGatewayForm {
    flex: 1;
    display: flex;
    flex-direction: column;

    iframe {
      width: 100% !important;
      height: auto !important;
      flex: 1;
    }
  }
`;

const BepaidInfo = styled.div`
  font-size: 10px;
  text-align: center;
`;

const NoteInfo = styled(FormInfo as any)`
  color: ${(props) => props.theme.colors.text};
  text-align: center;
`;

const Notes = styled.div`
  margin-top: 20px;
`;

const Logos = styled.div`
  display: block;
  margin: 0 auto;
  background: url(${AppTheme.img.payment.formLogos}) center no-repeat;
  background-size: contain;
  height: 40px;
  margin-top: 15px;
`;
