import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { createTheme, Dialog, ThemeProvider } from '@mui/material';
import { GoogleLogin } from './googleLogin';
import { FacebookLogin } from './facebookLogin';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import { Routes } from '../../../../store/routes';
import { Pages } from '../../../../store/enums';
import { UserAgreementLink } from '../../../../components/UI';

const modalTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: 'rgba(28, 28, 28, 0.2)',
          backdropFilter: 'blur(76px)',
          borderRadius: '16px',
          minWidth: '334px',
          height: '310px',
          margin: 0,
        },
      },
    },
  },
});

export const ModalWindow = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { isActivePage } = useRoutesHelper();

  const handleModalOpen = () => {
    setIsOpen((open) => !open);
  };

  useLayoutEffect(() => {
    const element = document.getElementById('root');

    if (isOpen) {
      element.style.filter = 'blur(2px)';
    }

    return () => {
      element.removeAttribute('style');
    };
  }, [isOpen]);

  const isRegistrationPage = isActivePage(Routes.User, Pages.Registration);

  const FANATICKA_LOGO = '../../../../content/img/logo.svg';
  const GOOGLE_BUTTON_LOGO = '../../../../content/img/googleButtonLogo.svg';
  const FACEBOOK_BUTTON_LOGO = '../../../../content/img/googleFacebookLogo.svg';

  return (
    <>
      <ButtonModal onClick={handleModalOpen}>
        <ImageButtonLogo src={GOOGLE_BUTTON_LOGO} alt={'googleButtonLogo'} />
        <ImageButtonLogo src={FACEBOOK_BUTTON_LOGO} alt={'googleFacebookLogo'} />
      </ButtonModal>
      <ThemeProvider theme={modalTheme}>
        <Dialog open={isOpen} onClose={handleModalOpen} scroll={'body'}>
          <Container>
            <Header>
              <Logo src={FANATICKA_LOGO} alt={'logo'} />
              <ButtonClose onClick={handleModalOpen}>
                <Close />
              </ButtonClose>
            </Header>
            <ButtonGroup>
              <GoogleLogin />
              <FacebookLogin />
            </ButtonGroup>
            {isRegistrationPage && (
              <ModalNote>
                <UserAgreementLink title={t('Registration.UserAgreement')}>
                  {t('Registration.RegistrationAgreements')}{' '}
                </UserAgreementLink>
              </ModalNote>
            )}
          </Container>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

const ButtonModal = styled.button`
  height: 44px;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  cursor: pointer;
`;

const ImageButtonLogo = styled.img`
  width: 24px;
  height: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 61px;
`;

const Header = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
  position: relative;
`;

const Logo = styled.img`
  width: 122px;
  height: 28px;
  position: relative;
  left: 15px;
`;

const ButtonClose = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50px;
  position: absolute;
  right: 21px;
  background: transparent;
  border: 0 solid transparent;
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  transition: background 0.5s ease-out;

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }
`;

const Close = styled.div`
  width: 17px;
  height: 17px;
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;

  &:after {
    content: '';
    width: 2px;
    height: 17px;
    position: absolute;
    background: #ffffff;
    transform: rotate(45deg);
    border-radius: 5px;
  }

  &:before {
    content: '';
    width: 2px;
    height: 17px;
    position: absolute;
    background: #ffffff;
    transform: rotate(135deg);
    border-radius: 5px;
  }
`;

const ModalNote = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.lighterFont};
  padding: 24px 17px;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0;
`;
