import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, Dialog, Skeleton, ThemeProvider } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { Ticket } from './ticket';
import { IGlobalStoreState } from '../../../store';
import { IBookStoreState } from '../../../store/book/types';
import { getEventById } from '../../../store/event/actions';
import { clearBook, initBook } from '../../../store/book/actions';
import { AlertType, KeyErrors } from '../../../store/enums';
import { Page, PageContent, PageHeader } from '../../../components/UI';
import { distributionInit } from '../../../store/distribution/actions';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { Routes } from '../../../store/routes';
import { Poster } from '../../../components/event';
import styled from 'styled-components';

const dialogTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: '15px 16px',
          background: '#1C1C1C',
          width: '410px',
          minHeight: '750px',
          padding: '13px 20px 16px 20px',
          filter: 'drop-shadow(0px 50px 80px rgba(0, 0, 0, 0.7))',
          [theme.breakpoints.down('sm')]: {
            width: '322px',
            minHeight: '650px',
            padding: '13px 15px 16px 15px',
          },
        }),
      },
    },
  },
});

export const ReferralPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const event = useSelector<IGlobalStoreState, IBookStoreState>((state) => state.book);
  const book = useSelector<IGlobalStoreState, IBookStoreState>((state) => state.book);
  const isRedirection = useSelector<IGlobalStoreState, boolean>((state) => state.app.isRedirection);
  const isFinalized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isFinalized);

  const [isLoading, setIsLoading] = useState(true);

  const { eventSlug, sectorSlug, distributorSlug, rowSlug, tickets, token } = useParams<{
    eventSlug: string;
    sectorSlug: string;
    rowSlug: string;
    tickets: string;
    token: string;
    distributorSlug: string;
  }>();

  const { getResultStatusRoute } = useRoutesHelper();

  useLayoutEffect(() => {
    if (isRedirection || !isFinalized) {
      return;
    }
    dispatch(clearBook());
  }, []);

  useEffect(() => {
    if (distributorSlug) {
      dispatch(distributionInit(distributorSlug));
    }
    if (!eventSlug) {
      history.push(Routes.Main);
      return;
    }
    // @ts-ignore
    dispatch(getEventById(eventSlug, null, token)).then((event) => {
      if (!event || !event.slug) {
        dispatch(clearBook());
        dispatch(
          setResultInfo({
            type: AlertType.Error,
            key: KeyErrors.EventNotFound,
            title: 'Alert.SomethingWentWrong',
            message: 'Alert.CheckLinkNoEventFound',
          })
        );
        history.replace(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound));
        return;
      }
      dispatch(initBook(event, sectorSlug, book.seats));
    });
    setIsLoading(false);
  }, [isFinalized]);

  useEffect(() => {
    const element = document.getElementById('root');
    element.style.filter = 'blur(2px)';

    return () => {
      element.removeAttribute('style');
    };
  }, []);

  const eventDetails = event?.event;

  return (
    <Page>
      <Header diagonal={true} stretch={false}>
        <Poster src={eventDetails?.posterUrl} alt={eventDetails?.title} title={eventDetails?.title} />
        <PageContent>
          <ThemeProvider theme={dialogTheme}>
            <Dialog open scroll={'body'}>
              {isLoading && <Skeleton animation="wave" variant="rectangular" width="50vw" height="80vh" />}
              {!isLoading && <Ticket event={event} />}
            </Dialog>
          </ThemeProvider>
        </PageContent>
      </Header>
    </Page>
  );
};

const Header = styled(PageHeader)`
  justify-content: flex-end;
  max-height: 60vh;
`;
