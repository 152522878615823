import { Pages } from './enums';

export class Routes {
  public static Main = '/';
  public static MainSortLocation = '/?city=city';
  public static NewDashboard = '/newDashboard';
  public static Category = `/${Pages.Categories}/:categorySlug`;
  public static Event = `/${Pages.EventDetails}/:eventSlug`;
  public static TokenReferral = '/tr/:distributorSlug/:eventSlug/:token';
  public static Referral = `/${Pages.Referral}/:distributorSlug/:eventSlug?/:sectorSlug?/:rowSlug?/:tickets?`;
  public static Book = `/${Pages.Book}/:eventSlug/:sectorSlug?/:quotaId?/:rowSlug?/:tickets?`;
  public static Checkout = `${Routes.Book}/${Pages.Checkout}`;
  public static BookResult = `/${Pages.Book}/result/:type?`;
  public static BookResultError = `/${Pages.Book}/resulterror/`;
  public static User = `/${Pages.User}`;
  public static Registration = `${Routes.User}/${Pages.Registration}`;
  public static Login = `${Routes.User}/${Pages.Login}`;
  public static Profile = `${Routes.User}/${Pages.Profile}`;
  public static EditProfile = `${Routes.User}/${Pages.EditProfile}`;
  public static UserConfirmEmail = `${Routes.User}/${Pages.EmailConfirm}/:status/:token?`;
  public static UserEvents = `${Routes.User}/${Pages.UserEvents}`;
  public static UserTickets = `${Routes.User}/${Pages.UserTickets}`;
  public static UserTicketDetails = `${Routes.UserTickets}/:masterTicketId/:ticketId?`;
  public static UserTicketSellDetails = `${Routes.UserTickets}/sell/:masterTicketId/:ticketId?`;
  public static UserTicketPurchase = `${Routes.UserTickets}/purchase/:masterTicketId/:ticketId?`;
  public static Share = `/${Pages.Sell}/:eventSlug/:sectorSlug?/:rowSlug?/:tickets?`;
  public static ShareLink = `/${Pages.Share}/:eventSlug/:token`;
  public static PasswordRecovery = `${Routes.User}/${Pages.PasswordRecovery}`;
  public static ResetPassword = `${Routes.User}/${Pages.ResetPassword}`;
  public static Contacts = `/${Pages.Contacts}`;
  public static PaymentsInfo = `/${Pages.PaymentsInfo}`;
  public static WidgetBook = `/${Pages.Widget}/${Pages.Book}/:token`;
  public static WidgetCheckout = `/${Pages.Widget}/${Pages.Book}/:token/checkout`;
  public static WidgetBookResult = `/${Pages.Widget}/${Pages.Book}/:token/result/:type?`;
  public static Success = `/${Pages.Success}`;
  public static Failed = `/${Pages.Failed}`;
  public static InformationSuccess = `/${Pages.Success}/:keySuccess`;
  public static InformationError = `/${Pages.Failed}/:keyFailed`;
  public static WidgetInformationSuccess = `/${Pages.Widget}/${Pages.Success}/:keySuccess`;
  public static WidgetInformationFailed = `/${Pages.Widget}/${Pages.Failed}/:keyFailed`;
}
