import Axios, { AxiosInstance } from 'axios';

import { IGlobalStoreState } from '../store';
import { store } from '../index';
import { AlertType, KeyErrors, RequestType } from '../store/enums';
import { clearError, hideSpinner, setError } from '../store/app/actions';
import { ErrorStoreState } from '../store/app/types';
import { logout } from '../store/user/actions';
import { setResultInfo } from '../store/resultInformarion/actions';

export class BaseApiService {
  protected config: any;
  protected service: AxiosInstance;

  constructor(config?: any) {
    this.config = config || {};
    this.service = Axios.create({
      baseURL: config.baseURL || '',
      withCredentials: false,
      headers: {
        Authorization: '',
      },
    });
  }

  get(url: string, config?: any, requestType?: RequestType) {
    config = this.getProcessedConfig(config);
    return this.processRequest(this.service.get(url, config), requestType);
  }

  post(url: string, data?: any, config?: any, requestType?: RequestType) {
    data = data || {};
    config = this.getProcessedConfig(config);
    return this.processRequest(this.service.post(url, data, config), requestType);
  }

  put(url: string, data?: any, config?: any) {
    data = data || {};
    config = this.getProcessedConfig(config);
    return this.processRequest(this.service.put(url, data, config));
  }

  delete(url: string, config?: any) {
    config = this.getProcessedConfig(config);
    return this.processRequest(this.service.delete(url, config));
  }

  processRequest(request: Promise<any>, requestType?: RequestType) {
    this.preProcessRequest(requestType);
    return request
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error, requestType ?? RequestType.none));
  }

  preProcessRequest(requestType) {
    const { error } = store.getState().app;

    if (!error || error.requestType !== requestType) {
      return;
    }

    store.dispatch(clearError());
  }

  handleResponse(data: any) {
    return Promise.resolve(data);
  }

  handleError(error: any, requestType: RequestType) {
    if (!error.response || error.response.status === 500) {
      store.dispatch(hideSpinner());
      store.dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.ServerError,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.ServerError',
        })
      );
    } else if (error.response.status === 401) {
      store.dispatch(logout());
    } else {
      const errorModel = new ErrorStoreState(
        error.response.data.Message || error.response.data,
        error.response.data.MessageDetails,
        requestType
      );
      store.dispatch(clearError());
      store.dispatch(setError(errorModel));
    }
    return Promise.reject(error);
  }

  private getProcessedConfig(config: any): any {
    const currentConfig = { ...this.config, ...config };
    currentConfig.headers = currentConfig.headers || {};
    currentConfig.headers = this.getAuthHeaders(currentConfig.headers);
    return currentConfig;
  }

  private getAuthHeaders(headers: any): any {
    const state = <IGlobalStoreState>store.getState();
    const authorization = state.auth && state.auth.access_token ? `Bearer ${state.auth.access_token}` : '';
    return {
      ...headers,
      Authorization: authorization,
    };
  }
}
